import { addDictionary } from "@cospex/client/helpers/dictionary";

const dictionary = {
  en: {
    "footer-description":
      "We are a team of exceptional software engineers, creating stunning web and mobile applications.",
    "footer-link-cookies": "Cookie policy",
    "footer-link-privacy": "Privacy Policy",
    "footer-link-terms": "Terms and conditions",
    "footer-link-contact": "Contact us",
    "footer-link-pricing": "Prices",
    "footer-link-refunds": "Refunds",
    "footer-link-login": "To log in",
    "footer-link-unsubscribe": "unsubscribe",
  },
  fr: {
    "footer-description": "Sélectionnez la langue du site ci-dessous",
    "footer-link-cookies": "Politique de cookies",
    "footer-link-privacy": "Politique de confidentialité",
    "footer-link-terms": "Termes et conditions",
    "footer-link-contact": "Contactez-nous",
    "footer-link-pricing": "Tarifs",
    "footer-link-refunds": "Remboursements",
    "footer-link-login": "Se connecter",
    "footer-link-unsubscribe": "Se désabonner",
  },
  it: {
    "footer-description":
      "Siamo un team di eccezionali ingegneri del software, che creano straordinarie applicazioni web e mobili.",
    "footer-link-cookies": "Politica sui cookie",
    "footer-link-privacy": "Informativa sulla privacy",
    "footer-link-terms": "Termini e condizioni",
    "footer-link-contact": "Contattaci",
    "footer-link-pricing": "Prezzi",
    "footer-link-refunds": "Rimborsi",
    "footer-link-login": "Accedere",
    "footer-link-unsubscribe": "disiscriversi",
  },
  es: {
    "footer-description":
      "Somos un equipo de ingenieros de software excepcionales que creamos impresionantes aplicaciones web y móviles.",
    "footer-link-cookies": "Política de cookies",
    "footer-link-privacy": "política de confidencialidad",
    "footer-link-terms": "Términos y Condiciones",
    "footer-link-contact": "Contáctenos",
    "footer-link-pricing": "Tarifas",
    "footer-link-refunds": "Reembolsos",
    "footer-link-login": "Conectarse",
    "footer-link-unsubscribe": "darse de baja",
  },
  de: {
    "footer-description":
      "Wir sind ein Team außergewöhnlicher Softwareentwickler, die beeindruckende Web- und Mobilanwendungen erstellen.",
    "footer-link-cookies": "Cookie-Richtlinie",
    "footer-link-privacy": "Datenschutzrichtlinie",
    "footer-link-terms": "Geschäftsbedingungen",
    "footer-link-contact": "Kontaktiere uns",
    "footer-link-pricing": "Preise",
    "footer-link-refunds": "Rückerstattungen",
    "footer-link-login": "Einloggen",
    "footer-link-unsubscribe": "abbestellen",
  },
};
const hook = addDictionary(dictionary, "reverse/components/Footer");
export default hook;
