import ErrorComponent from "@cospex/client/components/Error";
import FrontAppChat from "@cospex/client/components/FrontAppChat";
import Header from "@cospex/client/components/Header";
import * as Sentry from "@sentry/react";
import { Outlet } from "react-router-dom";

import Footer from "../components/Footer";

export default function App() {
  return (
    <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
      <FrontAppChat chatId="f8d099d6c75df355e612fc61e7b090e8" />
      <Header
        menu={{
          dashboardHref: "/dashboard/search",
        }}
      />
      <Outlet />
      <Footer />
    </Sentry.ErrorBoundary>
  );
}
